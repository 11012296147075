:root {
  --primary-text: #1f1f1f;
  --secondary-text: #ffffff;
  --primary-bg: #174aff;
  --secondary-bg: #000000;
}

/* Css normalization */
html {
  padding: 0;
  margin: 0;
  font-size: calc(16px + 0.0369vw);
}

*,
*::before,
*::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5 {
  font-style: normal;
  font-weight: 700;
  line-height: 31px;
}

body {
  font-family: 'Montserrat', sans-serif;
}

p {
  line-height: 1.55rem;
  letter-spacing: 1.55px;
}
